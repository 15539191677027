// @flow
import React from 'react';
import type { TSx } from '@graphite/types';
import Box from '../Box';

export type TProps = $ReadOnly<{|
	variant?: string,
	onChange?: InputEvent => void,
	placeholder?: string,
	value?: string,
	sx?: TSx,
	type?: 'password' | 'text' | 'search',
	name?: string,
	disabled?: boolean,
	width?: string,
	max?: number,
|}>;

const Input = (props: TProps, ref) => {
	const { variant = 'input', ...otherProps } = props;

	return (
		<Box
			as="input"
			type="text"
			variant={variant}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...otherProps}
			ref={ref}
		/>
	);
};

export default React.memo<TProps, HTMLInputElement>(React.forwardRef(Input));
